import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { dirname, relative } from '@common/util/path';
import { ImagesFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';

export interface ProjectFilesAddImageDialogData {
  commitId: string;
  projectId: number;
  filePath: string;
}

@Component({
  selector: 'mc-project-files-add-image-dialog',
  templateUrl: './project-files-add-image-dialog.component.html',
  styleUrls: ['./project-files-add-image-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProjectFilesAddImageDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '85rem',
    height: '45rem',
    autoFocus: '.mc-project-files-add-image-dialog-project-images-tree'
  };

  // Dialog data
  commitId: string;
  projectId: number;
  filePath: string;

  treeFileFilter: string = ImagesFilter;
  ProjectFolder: typeof ProjectFolder = ProjectFolder;

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<ProjectFilesAddImageDialogComponent>
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.commitId = this.data.commitId;
    this.projectId = this.data.projectId;
    this.filePath = this.data.filePath;
  }

  onInsertClicked(imagePath: string) {
    if (!imagePath) {
      return;
    }

    const relativeSrc: string = relative(dirname(this.filePath), imagePath);
    this.closeDialog(relativeSrc);
  }
}
