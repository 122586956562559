import { ElementRef, NgZone } from '@angular/core';
import { GutterItem, GutterItemType } from '@common/prosemirror/plugins/gutter.plugin';
import { DomService } from '@portal-core/general/services/dom.service';

interface GutterLayoutObject {
  height: number;
  invalidTarget?: boolean;
  top: number;
}

export class GutterLayoutObjectModel {
  private items: GutterLayoutObject[];
  private itemCommentCache: Map<string, string> = new Map<string, string>();
  private itemSpacing: number = 10;
  private verticalItemOuterSpacing: number = 1; // 1px so that the SVG lines do not get cut off at the top and bottom of the gutter
  private rafRequestId: number;

  constructor(gutterItems: GutterItem[], private domService: DomService, private ngZone: NgZone) {
    this.reset(gutterItems);
  }

  reset(gutterItems: GutterItem[]) {
    const newItemCommentCache = new Map<string, string>();

    this.items = Array(gutterItems.length);

    // Initialize the gutter layout objects and keep items from the old cache only if they are in the new set of gutterItems
    for (let i = 0; i < this.items.length; i += 1) {
      this.items[i] = {
        top: 0,
        height: 0
      };

      // Copy over existing cache items that match the gutter item's id
      const gutterItem = gutterItems[i];
      const gutterLayoutObject = this.itemCommentCache.get(gutterItem.id);
      if (gutterLayoutObject) {
        newItemCommentCache.set(gutterItem.id, gutterLayoutObject);
      }
    }

    this.itemCommentCache = newItemCommentCache;
  }

  // Returns the full height of the gutter
  getHeight(): number {
    const lastItem = this.items[this.items.length - 1];
    if (lastItem) {
      return lastItem.top + lastItem.height + this.verticalItemOuterSpacing;
    } else {
      return 0;
    }
  }

  layout(gutterItems: GutterItem[], itemElementRefs: ElementRef[], targetsContainer: HTMLElement, itemIndex: number = 0) {
    if (gutterItems.length > 0 && gutterItems.length === itemElementRefs.length) {
      this.layoutItem(gutterItems, itemElementRefs, targetsContainer, itemIndex);
      this.applyLayoutToDOM(itemElementRefs, itemIndex);
    }
  }

  forceLayout(gutterItems: GutterItem[], itemElementRefs: ElementRef[], targetsContainer: HTMLElement, itemIndex: number = 0) {
    if (gutterItems.length > 0 && gutterItems.length === itemElementRefs.length) {
      for (let i = 0; i < this.items.length; i += 1) {
        this.items.forEach(item => item.top = 0); // Resetting the top to zero will cause every item's layout to be recalculated
      }

      this.layout(gutterItems, itemElementRefs, targetsContainer, itemIndex);
    }
  }

  private layoutItem(gutterItems: GutterItem[], itemElementRefs: ElementRef[], targetsContainer: HTMLElement, itemIndex: number) {
    const item = gutterItems[itemIndex];
    const itemElement: HTMLElement = itemElementRefs[itemIndex].nativeElement;
    const targetElement: HTMLElement = targetsContainer.querySelector(item.targetSelector);
    const itemLayoutObject = this.items[itemIndex];

    // Calculate the height of the item
    const heightChanged = this.resizeElementForLayout(itemElement, item);
    if (heightChanged || itemLayoutObject.height === 0) {
      itemLayoutObject.height = itemElement.offsetHeight;
    }

    // Calculate the top of the item
    let posChanged = false;
    if (targetElement) {
      itemLayoutObject.invalidTarget = false;

      // Start by lining the item up with the top of the target
      let newTop = this.domService.offsetFrom(targetElement, targetsContainer).top;
      if (newTop < this.verticalItemOuterSpacing) {
        newTop = this.verticalItemOuterSpacing;
      }

      // Check to see if the desired position overlaps the previous item
      const prevItemLayoutObject = itemIndex > 0 ? this.items[itemIndex - 1] : null;
      if (prevItemLayoutObject) {
        const prevItemBottom = prevItemLayoutObject.top + prevItemLayoutObject.height;

        // If the previous item is in this item's desired position then put this item directly after the previous item
        if (prevItemBottom + this.itemSpacing > newTop) {
          newTop = prevItemBottom + this.itemSpacing;
        }
      }

      posChanged = newTop !== itemLayoutObject.top;
      itemLayoutObject.top = newTop;
    } else {
      itemLayoutObject.invalidTarget = true;
    }

    // If this item's position or height changed or the item's target was not found then the next item may need to be repositioned
    if ((posChanged || heightChanged || itemLayoutObject.invalidTarget) && itemIndex + 1 < gutterItems.length) {
      this.layoutItem(gutterItems, itemElementRefs, targetsContainer, itemIndex + 1);
    }
  }

  private resizeElementForLayout(itemElement: HTMLElement, gutterItem: GutterItem): boolean {
    let heightChanged = false;

    // If this is an annotation itme then make the comment textarea tall enough that it doesn't scroll
    if (gutterItem.type === GutterItemType.Annotation) {
      const cachedComment = this.itemCommentCache.get(gutterItem.id);
      const itemComment = gutterItem.data['MadCap:comment'];

      // If the item's comment has changed since it was last cached
      if (itemComment !== cachedComment) {
        const textareaElement = itemElement.querySelector('textarea');

        if (textareaElement) {
          const currentHeight = textareaElement.style.height;

          // Remove height constraints and set the text value
          textareaElement.style.height = 'auto';
          textareaElement.value = itemComment;

          // Grab the new scroll height of the textarea
          const scrollHeight = textareaElement.scrollHeight;

          // If the textarea is scrollable then make the textarea tall enough to not need scrolling
          if (scrollHeight > textareaElement.offsetHeight) {
            textareaElement.style.height = `${scrollHeight}px`;
          }

          heightChanged = currentHeight !== textareaElement.style.height;
        }

        // Update the comment in the item's cache
        this.itemCommentCache.set(gutterItem.id, itemComment);
      }
    }

    return heightChanged;
  }

  private applyLayoutToDOM(itemElementRefs: ElementRef[], itemIndex: number) {
    // Most browsers do not execute the animation frame callbacks when the tab does not have focus.
    // This means its possible to queue multiple requests.
    // For example, another user could be making changes to the same document causing gutter item updates to be sent to this user.
    // But if this user does not have focus in this tab then we would be queuing a new callback for each gutter item update.
    // We only care about applying the latest layout to the dom so we cancel the previous request if it exists before making a new request.
    if (this.rafRequestId) {
      cancelAnimationFrame(this.rafRequestId);
    }

    this.ngZone.runOutsideAngular(() => {
      this.rafRequestId = requestAnimationFrame(() => {
        this.rafRequestId = null;

        for (let i = itemIndex; i < itemElementRefs.length; i += 1) {
          const itemLayoutObject = this.items[i];
          const itemElement: HTMLElement = itemElementRefs[i].nativeElement;

          if (itemLayoutObject.invalidTarget) {
            itemElement.style.display = 'none';
          } else {
            const newTop = `${itemLayoutObject.top}px`;

            if (itemElement.style.top !== newTop) {
              itemElement.style.top = newTop;
            }

            if (itemElement.style.display !== '') {
              itemElement.style.display = '';
            }
          }
        }
      });
    });
  }
}
