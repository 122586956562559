<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    Insert Image
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()" [mat-dialog-close]="false">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <mc-project-files-tree-item-preview #imagePreview [commitId]="commitId" [projectId]="projectId" [treeFileFilter]="treeFileFilter" [defaultPath]="ProjectFolder.Images + '/'"></mc-project-files-tree-item-preview>
  </div>

  <div class="mc-spring-board-footer">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button (click)="onInsertClicked(imagePreview.selectedFilePath)" color="primary" [disabled]="!imagePreview.previewSrc || (imagePreview.previewLoadingState.loading$ | async) || (imagePreview.previewLoadingState.generalError$ | async)">Insert</button>
  </div>
</div>