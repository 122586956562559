import { getAttrs } from '@common/html/util/dom';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { modifyDomOutputSpec } from '@common/util/schema';
import { isEmpty, omit, omitBy } from 'lodash';
import { AttributeSpec, DOMOutputSpec, MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';

const madcapCustomAttrName = 'MadCap:custom';

export class CustomAttributesSchemaPlugin extends SchemaPlugin {
  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) {
    Object.entries(nodes).forEach(([name, node]) => {
      this.assignCustomAttributes(name, node);
    });

    Object.entries(marks).forEach(([name, mark]) => {
      this.assignCustomAttributes(name, mark);
    });
  }

  private assignCustomAttributes(name: string, spec: MarkSpec | NodeSpec) {
    if (name !== 'text') {
      // Add the custom attribute
      spec.attrs = Object.assign<Dictionary<AttributeSpec>, Dictionary<AttributeSpec>>(spec.attrs ?? {}, {
        [madcapCustomAttrName]: {
          default: undefined,
          toFlareXML: (value: Dictionary<string>): Dictionary<string> => {
            return value;
          }
        }
      });

      const omitAttrs = Object.keys(spec.attrs).concat('data-pm-slice').map(name => name.toLowerCase());

      // Update the node's parse rules to read in the custom attributes
      if (Array.isArray(spec.parseDOM)) {
        spec.parseDOM.forEach(parser => {
          const originalGetAttrs = parser.getAttrs;

          parser.getAttrs = function (element: Element) {
            let attrs = originalGetAttrs ? originalGetAttrs.apply(this, arguments) : {};

            // Do not modify the attrs if it is a false match (aka not a match)
            if (attrs === false) {
              return false;
            }

            // Get all attributes including ones not in the schema or originalGetAttrs
            const allAttrs = getAttrs(element);
            attrs = Object.assign(allAttrs || {}, attrs);
            const customAttrs = omitBy(attrs, (value, key) => omitAttrs.includes(key.toLowerCase()));

            if (!isEmpty(customAttrs)) {
              attrs[madcapCustomAttrName] = customAttrs;
            }
            return attrs;
          };
        });
      }

      // Update the toDOM method to include the custom attributes
      const originalToDOM = spec.toDOM;
      spec.toDOM = function (node: ProseMirrorNode): DOMOutputSpec {
        const dom = originalToDOM.apply(this, arguments);

        // If there are custom attributes on this node
        const customAttrs = node.attrs[madcapCustomAttrName];
        if (customAttrs) {
          modifyDomOutputSpec(dom, {
            attrs: {
              add: omit(customAttrs, 'style')
            }
          });
        }

        return dom;
      };
    }
  }
}
